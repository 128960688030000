export const featurePackageSettingFields = [
  {
    id: 'enableMGM',
    label: 'Enable MGM',
    defaultValue: false
  },
  {
    id: 'contactEnabled',
    label: 'Enable Contact Management',
    defaultValue: false
  },
  {
    id: 'enableShoppingCart',
    label: 'Enable Shopping Cart',
    defaultValue: false
  },
  {
    id: 'isBooking',
    label: 'Enable booking',
    defaultValue: false
  },
  {
    id: 'isBooking2',
    label: 'Enable Reservation',
    defaultValue: false
  },
  {
    id: 'enablePromotion',
    label: 'Enable Promotion',
    defaultValue: false
  },
  {
    id: 'enableCoupon',
    label: 'Enable Coupon',
    defaultValue: false
  },
  {
    id: 'enablePackage',
    label: 'Enable Package',
    defaultValue: false
  },
  {
    id: 'enableWallet',
    label: 'Enable Wallet',
    defaultValue: false
  },
  {
    id: 'isTransfer',
    label: 'Enable transfer',
    defaultValue: false
  },
  {
    id: 'isShortcut',
    label: 'Enable shortcut',
    defaultValue: false
  },
  {
    id: 'enableLineNotification',
    label: 'Enable Line Notification',
    defaultValue: false
  },
  {
    id: 'enable2c2p',
    label: 'Enable 2c2p',
    defaultValue: false
  },
  {
    id: 'shoppingPointsByConversionEnabled',
    label: 'Enable Shopping Points By Conversion rate',
    defaultValue: false
  },
  {
    id: 'enableSalesQr',
    label: 'Enable Salesperson Qr',
    defaultValue: false
  },
  {
    id: 'enableGas',
    label: 'Enable Gas Service',
    defaultValue: false
  },
  {
    id: 'enableCommission',
    label: 'Enable Commission',
    defaultValue: false
  }
];
